import React, { useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HomeSearchComponent from "./HomeSearchComponent";
import RadissonResource from "./RadissonResource";
import { CssBaseline } from "@mui/material";
import ErrorBoundary from './ErrorBoundary'; // Adjust the import path

import HTMLFlipBook from "react-pageflip";
import Image from 'mui-image'

function App() {

  // Create state variable and setter function using useState
  const [count, setCount] = useState(0);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const initialMediaSize = 
    isXs
    ? 'xs'
    : isSm
    ? 'sm'
    : isMd
    ? 'md'
    : isLg
    ? 'lg'
    : isXl
    ? 'xl'
    : 'Unknown';

  const [screenSize, setScreenSize] = useState(initialMediaSize);

  useEffect(() => {
    if (isXs) setScreenSize('xs');
    else if (isSm) setScreenSize('sm');
    else if (isMd) setScreenSize('md');
    else if (isLg) setScreenSize('lg');
    else if (isXl) setScreenSize('xl');
    else setScreenSize('Unknown');
  }, [isXs, isSm, isMd, isLg, isXl]);

  return (
    <ErrorBoundary>
    <React.Fragment>
    <CssBaseline />
    <Grid container row justifyContent="center" alignItems="center" 
      sx={{  width: "100vw", 
      height:{
        xs:"100vh",
        sm:"100dvh"
      }, 
        border:"0px solid red"}}>

      <Box sx={{ width: "100%", 
        height: {
          xs:"101%",
          sm:"100%",
          md:"100%",
          lg:"100%",
          xl:"100%",
        },
      overflowY:"auto",
       border:"0px solid blue"}}>
 {/*
      <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              width: { md: "100%"},
              overflowY:"auto"       
            }}
          >
              <HTMLFlipBook
                  width={300}
                  height={500}
                  maxShadowOpacity={0.5}
                  showCover={true}
                  mobileScrollSupport={true}
                >

                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-01.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-02.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-03.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-04.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-05.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-06.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-07.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-08.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-09.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-10.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-11.png"} width="300px" height="500px" /></div>
                  <div><img alt="image" style={{border: "1px solid #cccccc"}} src={"magazine/spring-summer-page-12.png"} width="300px" height="500px" /></div>

                </HTMLFlipBook>
        </Grid>

         */}

          <HomeSearchComponent
                screenSize={screenSize}
                theme={{
                  headerColor:"#FFFFFF",
                  headerTextColor:"#0C653A",
                  textColor:"#0C653A",
                  bannerColor:"#0C653A",
                  highlightColor:"#FAFA00",
                  bannerTextColor:"#FFFFFF",
                  buttonVariant:"outlined",
                  buttonColor:"#FFFFFF",
                  buttonTextColor:"#FFFFFF",
                  buttonHoverColor:"rgba(225,225,225,.25)",
                  background:"background.png"
              }}

              sx={{
                border: "0px solid #AAAAAA",
              }}

              apiKey="AIzaSyCwnvLcPG9h1g7rhnTqxXfaLJhj0AfjIQ8" />

            {/* <Sampler /> */}
      </Box>
    </Grid>
    </React.Fragment>    
    </ErrorBoundary>

  );
}

export default App;

/*
//green
theme={{
  headerColor:"#FFFFFF",
  headerTextColor:"#0C653A",
  textColor:"#0C653A",
  bannerColor:"#0C653A",
  highlightColor:"#FAFA00",
  bannerTextColor:"#FFFFFF",
  buttonVariant:"outlined",
  buttonColor:"#FFFFFF",
  buttonTextColor:"#FFFFFF",
  buttonHoverColor:"rgba(225,225,225,.25)",
  background:"background.png"
}}

//pink
theme={{
  headerColor:"#FFFFFF",
  headerTextColor:"#333333",
  textColor:"#f9cee7",
  bannerColor:"#f9cee7",
  highlightColor:"#FAFA00",
  bannerTextColor:"#FFFFFF",
  buttonVariant:"outlined",
  buttonColor:"#FFFFFF",
  buttonTextColor:"#FFFFFF",
  buttonHoverColor:"rgba(225,225,225,.25)",
  background:"background.png"
}}


//dark
theme={{
headerColor:"#FFFFFF",  
                  headerTextColor:"#333333",
                  textColor:"#333333",
                  bannerColor:"#000000",
                  highlightColor:"rgba(250, 250, 0,1)",
                  bannerTextColor:"#FFFFFF",
                  buttonVariant:"contained",
                  buttonColor:"#333333",
                  buttonBorderColor:"#888888",
                  buttonTextColor:"#FFFFFF",
                  buttonHoverColor:"rgba(225,225,225,.5)",
                  background:"background9.png"
  }}

//blue
theme={{
  headerColor:"#FFFFFF",  
  headerTextColor:"#333333",
  textColor:"#333333",
  bannerColor:"#DDDDDD",
  highlightColor:"rgba(250, 250, 0,1)",
  bannerTextColor:"#333333",
  buttonVariant:"contained",
  buttonColor:"#1976d2",
  buttonTextColor:"#FFFFFF",
  buttonHoverColor:"rgba(225,225,225,.25)"
  background:"background9.png"
  }}

  
*/