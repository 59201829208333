import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React, { Component } from "react";
import CommonTile from "./CommonTile";
import Footer from './Footer'
import SlideShowDialog from "./SlideShowDialog";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Speedometer from 'react-d3-speedometer';
import Slider from '@mui/material/Slider';
import ReactPlayer from 'react-player'
import PlayIcon from '@mui/icons-material/PlayCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Image from 'mui-image'
import _ from "lodash";

class ListingReport extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.mapImage = "general/cartoon-map.png"

    this.state = {
      photos:[],
      mortgagePayment:0,
      selectedTerm: '30',
      selectedPercentage: '5.5',
      selectedFinancing: '0',
      totalMonthlyPayment:0,
      showSlideShowDialog: false,
      househuntingInProgress: false,
      financeInProgress: false,
      pictureHeight: "100%",
      pictureWidth: "60%",
      favorites: [],
      zoomin:0,
      property:{ }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.listing !== this.props.listing) {
      if(this.props.listing){
        let principle = parseInt(.9*this.props.listing.ListPrice)
        this.setState({
          selectedTerm: '30',
          selectedPercentage: '5.5',
          selectedFinancing: principle,
          zoomin:0
        }, () => this.calculatePayment(principle, 5.5, 30))
      }
    }
}

sendHouseHuntingInfo = () => {
  if(this.props.listing){
    this.setState({househuntingInProgress:true }, () => {
      this.props.getHouseHuntingDownload(
        "ListingReport",
        this.props.listing.UnparsedAddress + ",  " + this.props.listing.PostalCity,
        this.props.listing.ListPrice,
        this.props.listing.BedroomsTotal,
        this.props.listing.BathroomsFull +"." + (this.props.listing.BathroomsHalf > 0 ? "5":"0"),
        this.props.listing.LivingArea,
        this.househuntingCallback)});
    }
}

househuntingCallback = (data) => {

  // Create a URL for the blob object
  const url = window.URL.createObjectURL(data);
    
  // Create a new link element
  const link = document.createElement('a');

  // Set the link's href attribute to the URL
  link.href = url;

  // Set the link's download attribute to the desired file name
  link.download = "househunting-report";

  // Click the link to initiate the download
  link.click();

  // Clean up the URL object
  window.URL.revokeObjectURL(url);

  this.setState({househuntingInProgress:false, requestSent:true, enableSubmit:false})
}


sendFinancingInfo = () => {
  if(this.props.listing){
    this.setState({financeInProgress:true }, () => {
      this.props.getFinanceDownload(
        "ListingReport",
        this.state.selectedFinancing,
        this.state.selectedPercentage,
        this.state.selectedTerm,
        parseInt((this.state.selectedFinancing * .0055)/12),
        parseInt(this.props.listing.TaxAnnualAmount/12),
        parseInt(((this.state.selectedFinancing/50000)*250)/12),
        0,
        this.callback)});
    }
}

callback = (data) => {

  // Create a URL for the blob object
  const url = window.URL.createObjectURL(data);
    
  // Create a new link element
  const link = document.createElement('a');

  // Set the link's href attribute to the URL
  link.href = url;

  // Set the link's download attribute to the desired file name
  link.download = "financing-report";

  // Click the link to initiate the download
  link.click();

  // Clean up the URL object
  window.URL.revokeObjectURL(url);

  this.setState({financeInProgress:false, requestSent:true, enableSubmit:false})
}


  toggleSlideShowDialog = () => {
    this.setState({
      showSlideShowDialog: !this.state.showSlideShowDialog,
      photos:[]
    }, () => this.props.getPhotoReport("ListingReport", this.props.listing.ListingId, this.props.listing.PhotosCount, this.callbackPhotos))
  }

  callbackPhotos = (response) => {
    if(response && response.status === 200 && response.data && response.data.searchResults && response.data.searchResults.length == 1){
      this.setState({photos:response.data.searchResults[0].Media})
    }
  }

  toggleListingReport = () => {
    this.setState({
      ListingReport: !this.state.ListingReport,
    });
  };

  toggleContactForm = () => {
    this.setState({
      showListing: null,
      contactForm: !this.state.contactForm,
      contactReason: "Lets Connect",
    });
  };

  toggleRequestForm = (listing) => {
    this.setState({
      showListing: this.props.listings[0],
      contactForm: !this.state.contactForm,
      contactReason: "Request A Showing",
    });
  };


  getVideoTile = () => {

     return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "50%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >

          <CommonTile
                uid={0}
                animationType={null}
                animationTime={null}
                tagTopRightIcon={null}
                tagTopRightTxt={null}
                tagTopRightPadding={"5px"}
                tagTopRightTxtColor={"black"}
                tagTopRightTxtSize={["12px", "12px", "16px"]}
                tagTopRightPaddingRight={"4px"}
                tagTopRightPaddingTop={"4px"}
                tagTopRightTxtType={"bold"}
                tagTopRightTxtAlign={"center"}
                tagTopRightBgColor={"rgba(255,255,255,.5)"}
                tagTopRightBorder={"0px solid black"}
                tagTopRightBorderRadius={"5px"}
                tagTopRightCallback={null}
                tagTopLeftIcon={null}
                tagTopLeftTxt={null}
                tagTopLeftPadding={"5px 20px 5px 20px"}
                tagTopLeftTxtColor={"black"}
                tagTopLeftTxtSize={["12px", "12px", "16px"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"0%"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={"#73be73"}
                tagTopLeftBorder={"0px solid black"}
                tagTopLeftBorderRadius={"0px"}
                topPadding={"0px"}
                topWidget={null}                
                topPicture={null}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={null}
                topTxtColor={"white"}
                topTxtSize={["12px", "12px", "32px"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topVerticalAlign={"flex-end"}
                topHorizontalAlign={"flex-start"}
                topBgColor={"rgbs(255,255,255,0)"}
                topHeight={["0px", "0%", "0%"]}
                topGraphType={null}
                topGraphData={null}
                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={"black"}
                mdlTagTxtSize={["12px", "12px", "16px"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={"rgba(255,255,255,0)"}
                mdlTagBorder={"0px solid black"}
                mdlTagBorderRadius={"5px"}                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={"Video Tour"}
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"0px solid black"}
                mdlHeight={["50px", "15%", "15%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"0px"}
                btmPicture={null}
                btmPictureAlign={"center"}
                btmPictureSize={"cover"}
                btmCallback={null}
                btmTxt={
                  <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="flex-start"
                      sx={{
                        background:"rgba(55,55,55,0)",
                        height: "100%",
                        width: "100%",
                        padding: "0px",  
                        overflowY:"hidden"             
                      }}
                    >
                     <ReactPlayer
                      playing={true}
                      controls={true}
                      muted={true}
                      url={this.props.listing.videos[0]}
                      width='100%'
                      height='100%'
                    />
                    </Grid>
                }
                btmTxtColor={"#333333"}
                btmTxtSize={["16px", "16px", "16px"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"flex-start"}
                btmBgColor={"white"}
                btmWidget={null}
                btmBorder={"1px solid black"}
                btmHeight={["350px", "85%", "85%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
    </Grid>
  </React.Fragment>
  );

}


  getPrimaryTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "50%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >

        {
          this.props.listing?
          <CommonTile
          uid={this.props.listing.ListingId}
          animationType={null}
          animationTime={null}
          tagTopRightIcon={ 
            this.props.favorites.some(obj => obj.ListingId === this.props.listing.ListingId) ? 
            <React.Fragment>
              <Image src={"logos/loveit-logo.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(this.props.listing)} fit="fit" height={35} width={55} duration={200}/>              
              </React.Fragment>              
              :
              <React.Fragment>
              <Image src={"logos/loveit-gray.png"} bgColor="rgba(255,255,255,0)" sx={{"&:hover": {cursor: "pointer"}}} onClick={() => this.props.markAsFavorite(this.props.listing)} fit="fit" height={35} width={55} duration={200}/>              
              </React.Fragment>                           
          }
          tagTopRightTxt={null}
          tagTopRightPadding={"2px 2px 2px 2px"}
          tagTopRightTxtColor={"black"}
          tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
          tagTopRightPaddingRight={"2px"}
          tagTopRightPaddingTop={"2px"}
          tagTopRightTxtType={"bold"}
          tagTopRightTxtAlign={"center"}
          tagTopRightBgColor={"rgba(255,255,255,0)"}
          tagTopRightBorder={"0px solid white"}
          tagTopRightBorderRadius={["0px","0px","0px","0px"]}
          tagTopRightCallback={null}

          tagBottomRightIcon={
            this.props.listing.ListAgentMlsId === "WILLSDO" ? <Image src={"logos/listedby-donna.png"} bgColor="rgba(255,255,255,0)" fit="fit" height={20} width={60} duration={200}/> : null
          }
          tagBottomRightTxt={
            null
          }
          tagBottomRightPadding={"5px 10px 5px 10px"}
          tagBottomRightTxtColor={"black"}
          tagBottomRightTxtSize={[".75rem", ".75rem", ".75rem"]}
          tagBottomRightPaddingRight={"0px"}
          tagBottomRightPaddingBottom={"0px"}
          tagBottomRightTxtType={"bold"}
          tagBottomRightTxtAlign={"center"}
          tagBottomRightBgColor={this.props.theme.highlightColor}
          tagBottomRightBorder={"0px solid black"}
          tagBottomRightBorderRadius={["10px", "0px", "0px", "0px"]}
          tagBottomRightCallback={null}

          tagTopLeftIcon={null}
          tagTopLeftTxt={
            this.props.listing.status
          }
          tagTopLeftPadding={"5px 10px 5px 10px"}
          tagTopLeftTxtColor={this.props.listing.status === "Available" ? this.props.theme.bannerTextColor : "black"}
          tagTopLeftTxtSize={[".75rem", ".75rem",  this.state.appSize === "small" ? ".75rem" : ".75rem"]}
          tagTopLeftPaddingLeft={"0px"}
          tagTopLeftPaddingTop={"5px"}
          tagTopLeftTxtType={"bold"}
          tagTopLeftTxtAlign={"center"}
          tagTopLeftBgColor={this.props.listing.status === "Available" ? "rgba(12,101,58,.95)" : "rgba(255, 165, 0,.95)"}
          tagTopLeftBorder={"0px solid black"}
          tagTopLeftBorderRadius={["0px", "20px", "20px", "0px"]}

          tagBottomLeftIcon={null}
          tagBottomLeftTxt={this.props.listing.videos && this.props.listing.videos.length > 0 ?                 
            <Button variant="filled" size="small" sx={{padding:"5px 10px 5px 10px", textTransform: "none", fontSize:".55rem", color:this.props.theme.bannercolor}} startIcon={<PlayIcon sx={{color:this.props.theme.bannerColor}}/>}>
              Video
            </Button>                
            : null
          }
          tagBottomLeftPadding={"0px"}
          tagBottomLeftTxtColor={this.props.theme.bannerColor}
          tagBottomLeftTxtSize={["1rem", "1rem", ".75rem"]}
          tagBottomLeftPaddingLeft={"0px"}
          tagBottomLeftPaddingBottom={"5px"}
          tagBottomLeftTxtType={"bold"}
          tagBottomLeftTxtAlign={"center"}
          tagBottomLeftBgColor={"rgba(255,255,255,1)"}
          tagBottomLeftBorder={"0px solid black"}
          tagBottomLeftBorderRadius={["0px", "10px", "10px", "0px"]}
          tagBottomLeftCallback={this.toggleSlideShowDialog}

          topCallback={this.toggleSlideShowDialog}
          topPadding={"0px"}
          topPicture={null}
          topPictureAlign={"center"}
          topPictureSize={"cover"}
          topTxt={null}
          topTxtColor={"white"}
          topTxtSize={["1.5rem", "1.5rem", ".75rem"]}
          topTxtType={"bold"}
          topTxtAlign={"left"}
          topVerticalAlign={"flex-end"}
          topHorizontalAlign={"flex-start"}
          topBgColor={"rgba(255,255,255,0)"}
          topHeight={["250px", "75%", "75%"]}
          topGraphType={null}
          topGraphData={null}
          topWidget={
            this.props.listing.Media && this.props.listing.Media.length > 0 ?
            <Image src={this.props.listing.Media[0]["MediaURL"]} fit="cover" duration={200} />
            :
            null
          }

          mdlTagIcon={null}
          mdlTagTxt={null}
          mdlTagPadding={"0px"}
          mdlTagTxtColor={"black"}
          mdlTagTxtSize={["1.5rem", "1.5rem", "1.5rem"]}
          mdlTagPaddingRight={"4px"}
          mdlTagPaddingTop={"2px"}
          mdlTagTxtType={"bold"}
          mdlTagTxtAlign={"center"}
          mdlTagBgColor={"rgba(255,255,255,0)"}
          mdlTagBorder={"0px solid black"}
          mdlTagBorderRadius={"5px"}                
          mdlPadding={"0px"}
          mdlPicture={null}
          mdlPictureAlign={null}
          mdlTxtLineHeight={1.5}
          mdlTxt={null}

          mdlWidget={
            
            <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "rgba(255,255,255,0)",
              height:"100%",
              width:"100%",     
              border: "0px solid #AAAAAA",
            }}
          >
              <Grid
                item
                sx={{
                  background: "rgba(255,255,255,0)",
                  overflowX:"hidden",
                  textAlign:"left",
                  fontSize: {
                    xs:"1rem",
                    sm:"1rem",
                    md:"1rem",
                    lg:"1rem",
                    xl:"1rem",
                  },
                  fontWeight:"normal",
                }}
                xs={10}
                sm={10}
                md={9}
                lg={10}
                xl={10}
              >

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: "rgba(255,255,255,0)",
                    height:"100%",
                    width:"100%",     
                    border: "0px solid #AAAAAA",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      textAlign:"left",
                      fontSize: {
                        xs:"1rem",
                        sm:"1rem",
                        md:"1rem",
                        lg:"1rem",
                        xl:"1rem"
                      },
                      fontWeight:"normal",
                    }}
                    xs={12}
                  >
                      <Box sx={{
                        fontSize:{
                        xs:"1.25rem",
                        sm: "1.25rem",
                        md:"1rem",
                        lg:"1rem",
                        xl:"1rem",
                        },
                        paddingLeft:"5px",
                        fontWeight:"bold", display:"inline"}} >
                    {"$"+(this.props.listing.ListPrice ? this.props.listing.ListPrice.toLocaleString(): "--")}
                    </Box>
                      &nbsp;|&nbsp;
                    {this.props.listing.LivingArea ? this.props.listing.LivingArea.toLocaleString(): "--"} sf &nbsp;|&nbsp;
                    {this.props.listing.BedroomsTotal} bd&nbsp;|&nbsp;
                    {this.props.listing.BathroomsFull}.{this.props.listing.BathroomsHalf} ba
                  </Grid>                           

                  <Grid
                    item
                    sx={{
                      overflowX:"hidden",
                      textAlign:"left",
                      paddingLeft:"5px",
                      fontSize: {
                        xs:"1rem",
                        sm:"1rem",
                        md:".85rem",
                        lg:".85rem",
                        xl:".85rem",
                      }
                    }}
                    xs={12}
                  >
                  {this.props.listing.UnparsedAddress + ", " + this.props.listing.PostalCity}
                </Grid>                 
              </Grid>                 
              </Grid>                 

              <Grid
                item
                sx={{
                  overflowX:"hidden",
                  textAlign:"left",
                  fontSize: {
                    xs:"1rem",
                    sm:"1rem",
                    md:"1rem",
                    lg:"1rem",
                    xl:"1rem",
                  },
                  fontWeight:"normal",
                  height:"100%",
                  width:"100%",     
                }}
                xs={2}
                sm={2}
                md={3}
                lg={2}
                xl={2}
              >

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                sx={{
                  background: "rgba(255,255,255,0)",
                  height:"100%",
                  width:"100%",     
                  border: "0px solid #AAAAAA",
                  paddingBottom:
                  {
                    xl:"0px",
                  }

                }}
              >
                {this.props.listing.ListAgentMlsId === "WILLSDO"?
                <Image src={"profilepics/profile-round-lowres.png"} bgColor="rgba(255,255,255,0)" fit="contain"  duration={200}/>              
                  :null}
              </Grid>

              </Grid>
          </Grid>            
          
            }
          mdlTxtColor={"black"}
          mdlTxtSize={["1rem", "1rem", "1rem"]}
          mdlTxtType={"normal"}
          mdlTxtAlign={"center"}
          mdlHorizontalAlign={"center"}
          mdlVerticalAlign={"flex-end"}
          mdlBgColor={this.props.listing.ListAgentMlsId === "WILLSDO" ? this.props.theme.highlightColor : "#FFFFFF" }
          mdlBorder={this.props.listing.ListAgentMlsId === "WILLSDO" ? "1px solid "+ this.props.theme.highlightColor : "0px solid black"}
          mdlHeight={["75px", this.props.listing.ListAgentMlsId === "WILLSDO" ? "20%" : "18%", this.props.listing.ListAgentMlsId === "WILLSDO" ? "20%" : "20%"]}
          mdlGraphType={null}
          mdlGraphData={null}                
          btmPadding={"0px 0px 2px 0px"}
          btmPicture={null}
          btmPictureAlign={null}
          btmTxt={ 
            <center>
            <div style={{fontSize:".5rem"}}>
            <img alt="image" src="howardhanna/idx-logo-sm.png" height="10px"/>  MLS:  {this.props.listing.ListingId} - Listing By {this.props.listing.ListOfficeName}
            </div>
          </center>     
          }
          btmTxtColor={"#333333"}
          btmTxtSize={[".75rem", ".75rem", ".75rem"]}
          btmTxtType={"normal"}
          btmTxtAlign={"left"}
          btmHorizontalAlign={"center"}
          btmVerticalAlign={"center"}
          btmBgColor={this.props.listing.ListAgentMlsId === "WILLSDO" ? this.props.theme.highlightColor : "#FFFFFF" }
          btmBorder={"0px solid black"}
          btmHeight={["25px", this.props.listing.ListAgentMlsId === "WILLSDO" ? "5%" : "7%", this.props.listing.ListAgentMlsId === "WILLSDO" ? "5%" : "5%"]}
          btmGraphType={null}
          btmGraphData={null}
          border={"1px solid black" }
          shadow={4}
          downloads={""}
          linksTo={""}
          growTime={4000}
          padding={"0px"}
          borderRadius={null}
          bgcolor={"rgba(255,255,255,1)"}
        />
        :
        null
        }
        </Grid>
      </React.Fragment>
    );
  }


  getDetailsTiles = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "50%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={null}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["0px", "32px", "24px"]}
            topTxtType={"strong"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["0px", "0px", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={"Features"}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "16px", "16px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"0px solid black"}
            mdlHeight={["50px", "15%", "15%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"2px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
                <Grid container sx={{overflowX:"hidden", width:"100%", height:"100%", padding:"0px"}} >
                    <Grid item sx={{borderRight:"1px solid #CCCCCC"}} xs={6}>
                     <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                          <Grid item sx={{fontWeight:"bold"}} xs={6}>Home Style</Grid><Grid item xs={6}>{this.props.listing && this.props.listing.ArchitecturalStyle ? this.props.listing.ArchitecturalStyle.replaceAll(",", " ") : "Residential"}</Grid>
                          <Grid item sx={{fontWeight:"bold"}} xs={6}>Year Built</Grid><Grid item xs={6}>{this.props.listing ? this.props.listing.YearBuilt : null}</Grid>
                          <Grid item sx={{fontWeight:"bold"}} xs={6}>Heating</Grid><Grid item xs={6}>{this.props.listing && this.props.listing.Heating ? this.props.listing.Heating.replaceAll(",", " ") : null}</Grid>
                          <Grid item sx={{fontWeight:"bold"}} xs={6}>Lot (Acres)</Grid><Grid item xs={6}>{this.props.listing ? this.props.listing.LotSizeAcres : null}</Grid>
                          <Grid item sx={{fontWeight:"bold"}} xs={6}>Lot (WxD)</Grid><Grid item xs={6}>{this.props.listing ? this.props.listing.LotSizeDimensions : null}</Grid>
                          <Grid item sx={{fontWeight:"bold"}} xs={6}>Garage</Grid><Grid item xs={6}>{this.props.listing ? this.props.listing.GarageSpaces : null}</Grid>
                          <Grid item sx={{fontWeight:"bold"}} xs={6}>Bedrooms</Grid><Grid item xs={6}>{this.props.listing ? this.props.listing.BedroomsTotal : null}</Grid>
                          <Grid item sx={{fontWeight:"bold"}} xs={6}>Bathrooms</Grid><Grid item xs={6}>{this.props.listing ? this.props.listing.BathroomsFull +"."+this.props.listing.BathroomsHalf : null}</Grid>
                          <Grid item sx={{fontWeight:"bold"}} xs={6}>High School</Grid><Grid item xs={6}>{this.props.listing ? this.props.listing.HighSchoolDistrict : null}</Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={{paddingLeft:"5px" }} xs={6}>
                      <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Basement</Grid><Grid item xs={6}>{this.props.listing && this.props.listing.Basement ? this.props.listing.Basement.replaceAll(",", " ") : null}</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Exterior</Grid><Grid item xs={6}>{this.props.listing && this.props.listing.ExteriorFeatures ? this.props.listing.ExteriorFeatures.replaceAll(",", " ") : null}</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Living Area</Grid><Grid item xs={6}>{this.props.listing && this.props.listing.LivingArea ? this.props.listing.LivingArea.toLocaleString() : null}</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Flooring</Grid><Grid item xs={6}>{this.props.listing && this.props.listing.Flooring ? this.props.listing.Flooring.replaceAll(",", " ") : null}</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Water/Sewer</Grid><Grid item xs={6}>{this.props.listing && this.props.listing.WaterSource ? this.props.listing.WaterSource.replaceAll(",", " ") : null}</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Taxes</Grid><Grid item xs={6}>{this.props.listing && this.props.listing.TaxAnnualAmount && this.props.listing.TaxAnnualAmount > 0 ? "$"+this.props.listing.TaxAnnualAmount.toLocaleString() : "No Data"}</Grid>
                        <Grid item sx={{fontWeight:"bold"}} xs={6}>Days On Market</Grid><Grid item xs={6}>{this.props.listing && this.props.listing.DaysOnMarket ? this.props.listing.DaysOnMarket : "No Data"}</Grid>
                      </Grid>
                    </Grid>
                </Grid>
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={[".75rem", ".75rem", ".75rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"1px solid black"}
            btmHeight={["350px", "85%", "85%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }

  getNearby = (place) => {

    let places = []
    if(this.props.listing && this.props.listing.NearbyPlaces){
      places = this.props.listing.NearbyPlaces[place]
    }  

    return (
      places.map((item) => {                          
          <Grid item xs={12}> item </Grid>
      })
    )
  }

  getWhatsNearbyTile = () => {
    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "50%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={null}
            topTxtColor={this.props.theme.bannerTextColor}
            topTxtSize={["0px", "32px", "24px"]}
            topTxtType={"strong"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={this.props.theme.bannerColor}
            topHeight={["0px", "0px", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={"What's Nearby"}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "16px", "16px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"0px solid black"}
            mdlHeight={["50px", "15%", "15%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
                <Grid container sx={{overflowX:"hidden", width:"100%", height:"100%", padding:"0px"}} >
                    <Grid item sx={{borderRight:"1px solid #CCCCCC"}} xs={4}>
                     <Grid container sx={{widht:"100%", height:"100%", padding:"0px"}} >
                       <Grid item xs={12} sx={{fontWeight:"bold"}}>Restaurants</Grid>
                       {this.props.listing && this.props.listing.NearbyPlaces && this.props.listing.NearbyPlaces["restaurant"].map((value, index) => (
                      

                                  <Grid item xs={12}>{value}</Grid>

                      ))}
                    </Grid>
                    </Grid>

                    <Grid item sx={{borderRight:"1px solid #CCCCCC", paddingLeft:"5px"}} xs={4}>
                      <Grid container sx={{widht:"100%", height:"100%"}} >
                        <Grid item xs={12} sx={{fontWeight:"bold"}}>Home Goods</Grid>
                        {this.props.listing && this.props.listing.NearbyPlaces && this.props.listing.NearbyPlaces["home_goods_store"].map((value, index) => (
                      

                                <Grid item xs={12}>{value}</Grid>

                          ))}
            
                      </Grid>
                    </Grid>

                    <Grid item sx={{paddingLeft:"5px" }} xs={4}>
                      <Grid container sx={{widht:"100%", height:"100%"}} >
                      <Grid item xs={12} sx={{fontWeight:"bold"}}>Groceries</Grid>
                      {this.props.listing && this.props.listing.NearbyPlaces && this.props.listing.NearbyPlaces["supermarket"].map((value, index) => (
                      

                            <Grid item xs={12}>{value}</Grid>

                      ))}
        
                      </Grid>
                    </Grid>

                </Grid>
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={[".75rem", ".75rem", ".75rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"hidden"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"1px solid black"}
            btmHeight={["350px", "85%", "85%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );


  }

  getNarativeTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "50%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"15px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"100px"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "32px", "24px"]}
            topTxtType={"strong"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(155,155,155,1)"}
            topHeight={["0px", "0px", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={"About This House"}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["1rem", "1rem", "1rem"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"0px solid black"}
            mdlHeight={["50px", "15%", "15%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
              this.props.listing ? this.props.listing.PublicRemarks : null
            }
            btmTxtColor={this.props.theme.textColor}
            btmTxtSize={["1rem", ".85rem", ".85rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"left"}
            btmTxtLineHeight={"1.25"}
            btmOverflow={"auto"}
            btmHorizontalAlign={"flex-start"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"white"}
            btmBorder={"1px solid black"}
            btmHeight={["350px", "85%", "85%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );

  }

  getMapTile = () => {
    let url_zoom4 = `https://maps.googleapis.com/maps/api/staticmap?center=${this.props.listing ? this.props.listing.Latitude : null},${this.props.listing ? this.props.listing.Longitude : null}&zoom=15&size=600x400&markers=icon:https://www.donnawills.com/mapmarkers/marker-dkgreen-home-64.png|${this.props.listing ? this.props.listing.Latitude : null},${this.props.listing ? this.props.listing.Longitude : null}&key=AIzaSyCwnvLcPG9h1g7rhnTqxXfaLJhj0AfjIQ8&format=png`
    let url_zoom3 = `https://maps.googleapis.com/maps/api/staticmap?center=${this.props.listing ? this.props.listing.Latitude : null},${this.props.listing ? this.props.listing.Longitude : null}&zoom=17&size=600x400&markers=icon:https://www.donnawills.com/mapmarkers/marker-dkgreen-home-64.png|${this.props.listing ? this.props.listing.Latitude : null},${this.props.listing ? this.props.listing.Longitude : null}&key=AIzaSyCwnvLcPG9h1g7rhnTqxXfaLJhj0AfjIQ8&format=png`
    let url_zoom2 = `https://maps.googleapis.com/maps/api/staticmap?center=${this.props.listing ? this.props.listing.Latitude : null},${this.props.listing ? this.props.listing.Longitude : null}&zoom=15&size=600x400&markers=icon:https://www.donnawills.com/mapmarkers/marker-dkgreen-home-64.png|${this.props.listing ? this.props.listing.Latitude : null},${this.props.listing ? this.props.listing.Longitude : null}&key=AIzaSyCwnvLcPG9h1g7rhnTqxXfaLJhj0AfjIQ8&format=png`
    let url_zoom1 = `https://maps.googleapis.com/maps/api/staticmap?center=${this.props.listing ? this.props.listing.Latitude : null},${this.props.listing ? this.props.listing.Longitude : null}&zoom=13&size=600x400&markers=icon:https://www.donnawills.com/mapmarkers/marker-dkgreen-home-64.png|${this.props.listing ? this.props.listing.Latitude : null},${this.props.listing ? this.props.listing.Longitude : null}&key=AIzaSyCwnvLcPG9h1g7rhnTqxXfaLJhj0AfjIQ8&format=png`

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "50%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >

          <CommonTile
                uid={0}
                animationType={null}
                animationTime={null}
                tagTopRightIcon={null}
                tagTopRightTxt={null}
                tagTopRightPadding={"5px"}
                tagTopRightTxtColor={"black"}
                tagTopRightTxtSize={["12px", "12px", "16px"]}
                tagTopRightPaddingRight={"4px"}
                tagTopRightPaddingTop={"4px"}
                tagTopRightTxtType={"bold"}
                tagTopRightTxtAlign={"center"}
                tagTopRightBgColor={"rgba(255,255,255,.5)"}
                tagTopRightBorder={"0px solid black"}
                tagTopRightBorderRadius={"5px"}
                tagTopRightCallback={null}
                tagTopLeftIcon={null}
                tagTopLeftTxt={null}
                tagTopLeftPadding={"5px 20px 5px 20px"}
                tagTopLeftTxtColor={"black"}
                tagTopLeftTxtSize={["12px", "12px", "16px"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"0%"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={"#73be73"}
                tagTopLeftBorder={"0px solid black"}
                tagTopLeftBorderRadius={"0px"}
                topPadding={"0px"}
                topWidget={null}                
                topPicture={null}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={null}
                topTxtColor={"white"}
                topTxtSize={["12px", "12px", "32px"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topVerticalAlign={"flex-end"}
                topHorizontalAlign={"flex-start"}
                topBgColor={"rgbs(255,255,255,0)"}
                topHeight={["0px", "0%", "0%"]}
                topGraphType={null}
                topGraphData={null}
                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={this.props.theme.bannerTextColor}
                mdlTagTxtSize={["12px", "12px", "16px"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={this.props.theme.bannerColor}
                mdlTagBorder={"0px solid black"}
                mdlTagBorderRadius={"5px"}
                                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={

                  <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    
                    height:"100%",
                    width: "100%",
                  }}
                >
                    

                    <Grid
                        item
                        sx={{
                          width: "100%",
                          fontSize: "1rem",            
                        }}
                        xs={12}
                      >
                        Location
                    </Grid>    

                    
                </Grid>    

                }
                mdlTxtColor={this.props.theme.bannerTextColor}
                mdlTxtSize={["1rem", "1rem", "1rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={this.props.theme.bannerColor}
                mdlBorder={"0px solid black"}
                mdlHeight={["50px", "15%", "15%"]}
                mdlGraphType={null}
                mdlGraphData={null}

                btmTagIcon={null}
                btmTagTxt={"Zoom"}
                btmTagPadding={"5px 10px 5px 20px"}
                btmTagTxtColor={this.props.theme.bannerTextColor}
                btmTagTxtSize={["12px", "12px", "16px"]}
                btmTagPaddingRight={"0px"}
                btmTagPaddingTop={"80%"}
                btmTagTxtType={"bold"}
                btmTagTxtAlign={"center"}
                btmTagBgColor={this.props.theme.bannerColor}
                btmTagBorder={"1px solid white"}
                btmTagBorderRadius={["15px", "0px", "0px", "15px"]}
                btmTagCallback={() => this.setState({zoomin: ((this.state.zoomin)+1)%4})}

    
                btmPadding={"0px"}
                btmPicture={this.state.zoomin == 0 ? url_zoom1 : this.state.zoomin == 1 ? url_zoom2 : this.state.zoomin == 2 ? url_zoom3 : url_zoom4}
                btmPictureAlign={"center"}
                btmPictureSize={"cover"}
                btmTxt={null}
                btmTxtColor={"#333333"}
                btmTxtSize={["16px", "16px", "16px"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmWidget={null}
                btmBorder={"1px solid black"}
                btmHeight={["350px", "85%", "85%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
    </Grid>
  </React.Fragment>
  );

}


  getMortgageTile = () => {

  }

  getNearbyRestaurants = (lat, lng) => {

    /*
    https://developers.google.com/maps/documentation/places/web-service/supported_types
    Hotels and lodging
    Museums and art galleries
    Parks and outdoor attractions
    Shopping centers and malls
    Bars and nightclubs
    Hospitals and medical centers
    Schools and universities
    Airports and train stations
    Gas stations and convenience stores


    function search(query) {
      var request = {
        query: query,
        fields: ['name', 'formatted_address', 'geometry']
      };

      var service = new google.maps.places.PlacesService(document.createElement('div'));

      service.textSearch(request, function(results, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          console.log(results);
        } else {
          console.error('Search failed:', status);
        }
      });
    }

    search('hotels in San Francisco');    
    */

    /*
      if(lat && lng){
      let llat = lat 
      let llng = lng
      // Create a new PlacesService object
      let location = new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng));

      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    
      // Define the search parameters
      const request = {
        location: {
          lat: 43.1833377059405,
          lng: -76.29868574434212
        },
        radius: 8046.72, // 5 miles in meters
        type: 'restaurant'
      };
    
      // Send the search request to the PlacesService
      placesService.nearbySearch(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          // Iterate through the search results and display the names and addresses of the restaurants
          results.forEach((place) => {
            console.log(place.name);
            console.log(place.vicinity);
          });
        } else {
          console.log(`PlacesService error: ${status}`);
        }
      });
    }
    */
  }

  getPictureTiles = () => {
    return (
      <React.Fragment>
        {this.props.listing && this.props.listing.Media ? this.props.listing.Media.map((picture, index) => {
          return (
            <Grid
              item
              sx={{
                
                padding: "10px",
                height: {
                  xl: "50%",
                  lg: "50%",
                  md: "50%",
                  sm: "50%",
                },
                background: "#FFFFFF",
              }}
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
            >
              <CommonTile
                uid={index}
                animationType={null}
                animationTime={null}
                tagTopRightIcon={null}
                tagTopRightTxt={null}
                tagTopRightPadding={"5px"}
                tagTopRightTxtColor={"black"}
                tagTopRightTxtSize={["1rem", "1rem", "1rem"]}
                tagTopRightPaddingRight={"4px"}
                tagTopRightPaddingTop={"4px"}
                tagTopRightTxtType={"bold"}
                tagTopRightTxtAlign={"center"}
                tagTopRightBgColor={"rgba(255,255,255,.5)"}
                tagTopRightBorder={"0px solid black"}
                tagTopRightBorderRadius={"5px"}
                tagTopRightCallback={null}
                tagTopLeftIcon={null}
                tagTopLeftTxt={null}
                tagTopLeftPadding={"5px 20px 5px 20px"}
                tagTopLeftTxtColor={"black"}
                tagTopLeftTxtSize={["1rem", "1rem", "1rem"]}
                tagTopLeftPaddingLeft={"0px"}
                tagTopLeftPaddingTop={"0%"}
                tagTopLeftTxtType={"bold"}
                tagTopLeftTxtAlign={"center"}
                tagTopLeftBgColor={"#73be73"}
                tagTopLeftBorder={"0px solid black"}
                tagTopLeftBorderRadius={"0px"}
                topPadding={"0px"}
                topPicture={picture["MediaURL"]}
                topPictureAlign={"center"}
                topPictureSize={"cover"}
                topTxt={" "}
                topCallback={this.toggleSlideShowDialog}
                topTxtColor={"white"}
                topTxtSize={["1rem", "1rem", "1rem"]}
                topTxtType={"bold"}
                topTxtAlign={"left"}
                topVerticalAlign={"flex-end"}
                topHorizontalAlign={"flex-start"}
                topBgColor={"rgbs(255,255,255,0)"}
                topHeight={["400px", "100%", "100%"]}
                topGraphType={null}
                topGraphData={null}
                mdlTagIcon={null}
                mdlTagTxt={null}
                mdlTagPadding={"0px"}
                mdlTagTxtColor={"black"}
                mdlTagTxtSize={["1rem", "1rem", "1rem"]}
                mdlTagPaddingRight={"4px"}
                mdlTagPaddingTop={"2px"}
                mdlTagTxtType={"bold"}
                mdlTagTxtAlign={"center"}
                mdlTagBgColor={"rgba(255,255,255,0)"}
                mdlTagBorder={"0px solid black"}
                mdlTagBorderRadius={"5px"}                
                mdlPadding={"0px"}
                mdlPicture={null}
                mdlPictureAlign={null}
                mdlTxt={null}
                mdlTxtColor={"black"}
                mdlTxtSize={["1rem", "1rem", ".75rem"]}
                mdlTxtType={"bold"}
                mdlTxtAlign={"center"}
                mdlHorizontalAlign={"center"}
                mdlVerticalAlign={"center"}
                mdlBgColor={"#FFFFFF"}
                mdlBorder={"0px solid black"}
                mdlHeight={["0px", "0%", "0%"]}
                mdlGraphType={null}
                mdlGraphData={null}
                btmPadding={"0px"}
                btmPicture={null}
                btmPictureAlign={null}
                btmTxt={null}
                btmTxtColor={"#333333"}
                btmTxtSize={["1rem", "1rem", ".75rem"]}
                btmTxtType={"normal"}
                btmTxtAlign={"left"}
                btmHorizontalAlign={"center"}
                btmVerticalAlign={"center"}
                btmBgColor={"white"}
                btmBorder={"0px solid black"}
                btmHeight={["0px", "0%", "0%"]}
                btmGraphType={null}
                btmGraphData={null}
                border={"1px solid black"}
                shadow={4}
                downloads={""}
                linksTo={""}
                growTime={4000}
                padding={"0px"}
                borderRadius={null}
                bgcolor={"rgba(255,255,255,1)"}
              />
            </Grid>
          );
        }) : null}
      </React.Fragment>
    );
  };

  
  getContactTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "Are you ready.  We can get you home listed and sold for a great price and faster than you might think.  OUr location is popular with buyers and our market is extremely good.  Contact me to learn more.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-end"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"25px"}
            topPicture={"profilepics/contact-profile.png"}
            topPictureAlign={"100% 100%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["200px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={

              <React.Fragment>              
              <Button
              onClick={() => this.props.displayRequestShowing([this.props.listing ])}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"85%",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                }
              }}
            >Request A Showing</Button>
              </React.Fragment>           
            }
            mdlCallback={null}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "1rem", "1rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["100px", "30%", "30%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmCallback={null}
            btmTxtColor={"white"}
            btmTxtSize={["16px", "1rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"rgba(25, 118, 210, 1)"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getSlideShowTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "Are you ready.  We can get you home listed and sold for a great price and faster than you might think.  OUr location is popular with buyers and our market is extremely good.  Contact me to learn more.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-end"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"0px"}
            topPicture={"general/slideshow.png"}
            topPictureAlign={"50% 50%"}
            topPictureSize={"cover"}
            topCallback={this.toggleSlideShowDialog}
            topTxt={" "}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["200px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={

              <React.Fragment>              
              <Button
              
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },
    
                width:"85%",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                }
              }}
            >View Slide Show, {this.props.listing ? this.props.listing.PhotosCount : null} photos</Button>
              </React.Fragment>           
            }
            mdlCallback={this.toggleSlideShowDialog}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "1rem", "1rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["100px", "30%", "30%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmCallback={null}
            btmTxtColor={"white"}
            btmTxtSize={["16px", "1rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"rgba(25, 118, 210, 1)"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };

  getFinanceReportTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "Are you ready.  We can get you home listed and sold for a great price and faster than you might think.  OUr location is popular with buyers and our market is extremely good.  Contact me to learn more.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTopLeftIcon={null}
            tagTopLeftTxt={null}
            tagTopLeftPadding={"5px 10px 5px 10px"}
            tagTopLeftTxtColor={"black"}
            tagTopLeftTxtSize={null}
            tagTopLeftPaddingLeft={"0px"}
            tagTopLeftPaddingTop={"0%"}
            tagTopLeftTxtType={"bold"}
            tagTopLeftTxtAlign={"center"}
            tagTopLeftBgColor={null}
            tagTopLeftBorder={"0px solid black"}
            tagTopLeftBorderRadius={"0px"}
            topPadding={"25px"}
            topPicture={"downloads/financing.png"}
            topPictureAlign={"50% 70%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["250px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={<React.Fragment>              
              <Button
              onClick={() => this.sendFinancingInfo}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },    
                width:"85%",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                }
              }}
            >Download My Payment Report</Button>
              </React.Fragment>           
            }
            mdlCallback={this.sendFinancingInfo}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "1rem", "1rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["100px", "30%", "30%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            mdlProgress={this.state.financeInProgress}
            mdlProgressSize={40}
            mdlProgressColor={this.props.theme.bannerColor}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmCallback={null}
            btmTxtColor={"white"}
            btmTxtSize={["16px", "1rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"rgba(25, 118, 210, 1)"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getHouseHuntingReportTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "Are you ready.  We can get you home listed and sold for a great price and faster than you might think.  OUr location is popular with buyers and our market is extremely good.  Contact me to learn more.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTopLeftIcon={null}
            tagTopLeftTxt={null}
            tagTopLeftPadding={"5px 10px 5px 10px"}
            tagTopLeftTxtColor={"black"}
            tagTopLeftTxtSize={null}
            tagTopLeftPaddingLeft={"0px"}
            tagTopLeftPaddingTop={"0%"}
            tagTopLeftTxtType={"bold"}
            tagTopLeftTxtAlign={"center"}
            tagTopLeftBgColor={null}
            tagTopLeftBorder={"0px solid black"}
            tagTopLeftBorderRadius={"0px"}
            topPadding={"25px"}
            topPicture={"downloads/ultimate-guide-house-hunting.png"}
            topPictureAlign={"50% 70%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["250px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={<React.Fragment>              
              <Button
              onClick={() => this.sendHouseHuntingInfoInfo}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },    
                width:"85%",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                }
              }}
            >Download My House Hunting Guide</Button>
              </React.Fragment>           
            }
            mdlCallback={this.sendHouseHuntingInfo}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "1rem", "1rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["100px", "30%", "30%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            mdlProgress={this.state.househuntingInProgress}
            mdlProgressSize={40}
            mdlProgressColor={this.props.theme.bannerColor}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmCallback={null}
            btmTxtColor={"white"}
            btmTxtSize={["16px", "1rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"rgba(25, 118, 210, 1)"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };

  getListingReportTile = () => {
    let tile = {
      id: "1",
      image: "profilepics/contact-profile.png",
      tag: null,
      name: "Request A Showing",
      summary:
      "Are you ready.  We can get you home listed and sold for a great price and faster than you might think.  OUr location is popular with buyers and our market is extremely good.  Contact me to learn more.",
      download: true,
    }

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTopLeftIcon={null}
            tagTopLeftTxt={null}
            tagTopLeftPadding={"5px 10px 5px 10px"}
            tagTopLeftTxtColor={"black"}
            tagTopLeftTxtSize={null}
            tagTopLeftPaddingLeft={"0px"}
            tagTopLeftPaddingTop={"0%"}
            tagTopLeftTxtType={"bold"}
            tagTopLeftTxtAlign={"center"}
            tagTopLeftBgColor={null}
            tagTopLeftBorder={"0px solid black"}
            tagTopLeftBorderRadius={"0px"}
            topPadding={"25px"}
            topPicture={"downloads/full-report-3.png"}
            topPictureAlign={"50% 70%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"flex-end"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["250px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={<React.Fragment>              
              <Button
              onClick={() => this.props.showContact}
              variant={this.props.theme.buttonVariant}
              sx={{
                '&:hover': {
                  border:"1px solid " + this.props.theme.buttonTextColor,
                  background:this.props.theme.buttonHoverColor
                },    
                width:"85%",
                color:this.props.theme.buttonTextColor,
                border:"1px solid " + this.props.theme.buttonColor,
                padding: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px"
                },
                marginRight: "0px",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
                borderBottomLeftRadius: "20px",
                textTransform: "none",
                textAlign: "center",
                fontSize:{
                  xs:"1rem",
                  sm:"1rem",
                  md:".75rem",
                  lg:".75rem",
                  xl:".75rem",

                }
              }}
            >Schedule A Home Valuation</Button>
              </React.Fragment>           
            }
            mdlCallback={this.props.showContact}
            mdlTxtColor={this.props.theme.bannerTextColor}
            mdlTxtSize={["16px", "1rem", "1rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={this.props.theme.bannerColor}
            mdlBorder={"1px solid black"}
            mdlHeight={["100px", "30%", "30%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmCallback={null}
            btmTxtColor={"white"}
            btmTxtSize={["16px", "1rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"rgba(25, 118, 210, 1)"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };

  getMarketConditionsTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-end"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"10px"}
            topPicture={null}
            topWidget={
              <div>
              <Speedometer
                  needleHeightRatio={0.7}
                  value={800}
                  customSegmentStops={[0, 250, 750, 1000]}
                  segmentColors={['#b0deb6', '#61bd6d', '#b0deb6']}
                  currentValueText="Sellers Market"
                  customSegmentLabels={[
                    {
                      text: 'Buyers',
                      position: 'OUTSIDE',
                      color: '#333333',
                    },
                    {
                      text: 'Neautral',
                      position: 'OUTSIDE',
                      color: '#333333',
                    },
                    {
                      text: 'Sellers',
                      position: 'OUTSIDE',
                      color: '#333333',
                    },
                  ]}
                  ringWidth={45}
                  needleTransitionDuration={3333}
                  needleTransition="easeElastic"
                  needleColor={'#333333'}
                  textColor={'#333333'}
              />
            </div>
            }
            topPictureAlign={"100% 100%"}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "6px", "20px"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["200px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"5px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={
              <div> <b>Baldwinsville</b> is in a <b>Sellers Market</b> which means you should anticipate multiple comeptitive offers on this home, some with escalation and limited contingencies.</div>

            }
            mdlCallback={null}
            mdlTxtColor={"#333333"}
            mdlTxtSize={["16px", ".85rem", ".85rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"flex-start"}
            mdlBgColor={"rgba(255, 255, 255, 1)"}
            mdlBorder={"1px solid black"}
            mdlHeight={["100px", "30%", "30%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={null}
            btmCallback={null}
            btmTxtColor={"white"}
            btmTxtSize={["16px", "1rem", "1rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"center"}
            btmBgColor={"rgba(25, 118, 210, 1)"}
            btmBorder={"0px solid black"}
            btmHeight={["0px", "0%", "0%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    
    // Remove non-numeric characters
    const numericInput = value.replace(/[^0-9]/g, '');
    this.setState({ [name]: numericInput });
  };

  calculatePayment = (principal, interestRate, years) => {

    // Calculate monthly interest rate
    const monthlyInterestRate = interestRate / 12 / 100;
  
    // Calculate number of monthly payments
    const numberOfPayments = years * 12;
  
    // Calculate mortgage payment
    const mortgagePayment =
      (principal *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
      (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
  
  
    this.setState({
      mortgagePayment: Math.floor(mortgagePayment),
      totalMonthlyPayment: Math.floor(mortgagePayment) + parseInt((this.state.selectedFinancing * .01)/12) + parseInt(this.props.listing.TaxAnnualAmount/12) + parseInt(((this.state.selectedFinancing/50000)*300)/12),
    })
  }

  handleFinancingChange = (event, newValue) => {
  
    this.setState({ selectedFinancing: newValue }, () => {
      this.calculatePayment(this.state.selectedFinancing, this.state.selectedPercentage, this.state.selectedTerm)
    });
  };

  handlePercentageChange = (event, newValue) => {
  
    this.setState({ selectedPercentage: newValue }, () => {
      this.calculatePayment(this.state.selectedFinancing, this.state.selectedPercentage, this.state.selectedTerm)
    });

  };

  
  handleTermChange = (event, newValue) => {
  
    this.setState({ selectedTerm: newValue }, () => {
      this.calculatePayment(this.state.selectedFinancing, this.state.selectedPercentage, this.state.selectedTerm)
    });

  };

  handleChange = (event, newValue) => {
    this.setState({ selectedTerm: newValue });
  };

  formatDollarValueLabel = (value) => {
    // Format the value as desired
    return "$"+value.toLocaleString();
  };

  formatPercentValueLabel = (value) => {
    // Format the value as desired
    return (Number(value.toFixed(2)) + "%");
  };

  formatTermValueLabel = (value) => {
    // Format the value as desired
    return (Number(value.toFixed(0)) + " yrs");
  };
  getFinancingTile = () => {

    return (
      <React.Fragment>
        <Grid
          item
          sx={{
            fontSize:".75rem",
            textAlign:"left",
            color:"#888888",
            padding: "10px",
            height: {
              xl: "50%",
              lg: "50%",
              md: "50%",
              sm: "30%",
            },
            background: "#FFFFFF",
          }}
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"center"}
            tagHorizontalAlign={"center"}
            tagBgColor={"#73be73"}
            tagBorder={"1px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"20px 50px 5px 50px"}
            topPicture={null}
            topWidget={
              <React.Fragment>
               <Slider
                marks={true}
                min={0}
                max={this.props.listing && this.props.listing.ListPrice ? Math.ceil(this.props.listing.ListPrice / 100000) * 100000 + 50000 : 1000000}
                step={this.props.listing && this.props.listing.ListPrice > 1000000 ? 10000 : 5000}
                valueLabelDisplay="on"
                getAriaLabel={() => 'Prices'}
                value={this.state.selectedFinancing}
                sx={{
                  '& .MuiSlider-valueLabel': {
                    fontSize: 10, // Set the font size here
                    background:this.props.theme.textColor,
                    marginTop:"3px"
                  },
                  color:this.props.theme.textColor
                }}
                valueLabelFormat={this.formatDollarValueLabel}
                onChange={this.handleFinancingChange}
              />   
              <Slider
              marks={true}
              min={1}
              max={10}
              step={.25}
              valueLabelDisplay="on"
              getAriaLabel={() => 'Percentage'}
              value={this.state.selectedPercentage}
              sx={{
                '& .MuiSlider-valueLabel': {
                  fontSize: 10, // Set the font size here
                  background:this.props.theme.textColor,
                  marginTop:"3px"
              },
                color:this.props.theme.textColor
              }}
              valueLabelFormat={this.formatPercentValueLabel}
              onChange={this.handlePercentageChange}
            />   
            <Slider
              marks={true}
              min={10}
              max={30}
              step={5}
              valueLabelDisplay="on"
              getAriaLabel={() => 'Term'}
              value={this.state.selectedTerm}
              sx={{
                '& .MuiSlider-valueLabel': {
                  fontSize: 10, // Set the font size here
                  background:this.props.theme.textColor,
                  marginTop:"3px"
              },
                color:this.props.theme.textColor
              }}
              valueLabelFormat={this.formatTermValueLabel}
              onChange={this.handleTermChange}
            />              
            </React.Fragment>
            }
            topPictureAlign={null}
            topPictureSize={"contain"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "1rem", "1rem"]}
            topTxtType={"plain"}
            topTxtAlign={"right"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"flex-end"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["300px", "70%", "70%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlWidget={null}
            mdlCallback={null}
            mdlTxtColor={"#333333"}
            mdlTxtSize={["16px", ".75rem", ".75rem"]}
            mdlTxtType={"normal"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"center"}
            mdlBgColor={"rgba(255, 255, 255, 0)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"5px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={ 
              <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "rgba(255,255,255,0)",
                          height:"100%",
                          width:"100%",     
                          border: "0px solid #AAAAAA",
                        }}
                      >
                <Grid
                  item
                  sx={{
                    background: "rgba(255,255,255,0)",
                    paddingTop: "0px",
                    height: {
                      md: "75%",
                      lg: "60%",
                    },
                    fontWeight:"normal",
                    border:"0px solid black"
                  }}
                >
                  If you borrowed ${this.state.selectedFinancing.toLocaleString()}, assumes a ${this.props.listing && this.props.listing.ListPrice > this.state.selectedFinancing ? parseInt((this.props.listing.ListPrice - this.state.selectedFinancing)).toLocaleString() : 0} down payment, your total monthly payment (taxes, insurance and mortgage) 
                  for this home would be approximately
                  </Grid>
                  <Grid
                  item
                  sx={{
                    background: "rgba(255,255,255,0)",
                    paddingTop: "0px",
                    height: {
                      md: "25%",
                      lg: "40%",
                    },
                    fontSize:{
                      xs:"1.75rem",
                      md:"1rem",
                      lg:"1.5rem"
                    },
                    fontWeight:"bold",
                    border:"0px solid black"
                  }}
                  xs={12}
                >
                  ${this.state.totalMonthlyPayment.toLocaleString()}
                </Grid>

              </Grid>
          }
            btmCallback={null}
            btmTxtColor={this.props.theme.bannerTextColor}
            btmTxtSize={["16px", ".85rem", ".85rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"center"}
            btmHorizontalAlign={"center"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={this.props.theme.bannerColor}
            btmBorder={"1px solid black"}
            btmHeight={["150px", "30%", "30%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"1px solid black"}
            shadow={4}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"0"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,1)"}
          />
        </Grid>
      </React.Fragment>
    );
  };


  getIdxTile = () => {
    return (
      <React.Fragment>
        
        <Grid
          item
          sx={{
            background: "rgba(255,255,255,0)",
            paddingTop: "10px",
            height: {
              xl: "12%",
              lg: "15%",
              md: "15%",
              sm: "50%",
            },
            border:"0px solid black"
          }}
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <CommonTile
            animationType={null}
            animationTime={null}
            tagPicture={null}
            tagTxt={null}
            tagTxtColor={"black"}
            tagTxtSize={["12px", "12px", "16px"]}
            tagTxtType={"bold"}
            tagTxtAlign={"center"}
            tagVerticalAlign={"flex-start"}
            tagHorizontalAlign={"flex-end"}
            tagBgColor={"#73be73"}
            tagBorder={"0px solid black"}
            tagBorderRadius={"0px"}
            topPadding={"0px"}
            topPicture={null}
            topPictureAlign={"left"}
            topPictureSize={"0px"}
            topTxt={null}
            topTxtColor={"black"}
            topTxtSize={["0px", "0px", "0px"]}
            topTxtType={"normal"}
            topTxtAlign={"left"}
            topHorizontalAlign={"center"}
            topVerticalAlign={"center"}
            topBgColor={"rgba(255,255,255,0)"}
            topHeight={["0px", "0%", "0%"]}
            topGraphType={null}
            topGraphData={null}
            mdlPadding={"0px"}
            mdlPicture={null}
            mdlPictureAlign={null}
            mdlTxt={null}
            mdlTxtColor={"black"}
            mdlTxtSize={["0px", "0px", "0px"]}
            mdlTxtType={"bold"}
            mdlTxtAlign={"center"}
            mdlHorizontalAlign={"center"}
            mdlVerticalAlign={"flex-start"}
            mdlBgColor={"rgba(255,255,255,0)"}
            mdlBorder={"0px solid black"}
            mdlHeight={["0px", "0%", "0%"]}
            mdlGraphType={null}
            mdlGraphData={null}
            btmPadding={"0px"}
            btmPicture={null}
            btmPictureAlign={null}
            btmTxt={
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          background: "rgba(255,255,255,0)",
                          height:"100%",
                          width:"100%",     
                          border: "0px solid #AAAAAA",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"right",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={2}
                          sm={2}
                          md={1}
                        >
                            <img alt="image" src="howardhanna/idx-lg.png" height={25} width={40}/>&nbsp;&nbsp;
                        </Grid>
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"left",
                            fontSize: {
                              xs:".55rem",
                              sm:".55rem",
                              md:".55rem",
                              lg:".55rem",
                              xl:".55rem"
                            },
                            height:"100%",
                            width:"100%",   
                            fontWeight:"normal",
                          }}
                          xs={10}
                        >                          
                          The data relating to real estate on this web site comes in part from the Internet Data Exchange (IDX) 
                          Program of NYSAMLS’s. Real estate listings held by firms other than Howard Hanna Real Estate Services, are marked 
                          with the Listing Broker’s Firm Name. Data provided is for consumers’ personal, non-commercial use and 
                          may not be used for any purpose other than to identify prospective properties that consumers may be 
                          interested in purchasing. Listing Data last updated at {this.props.listing ? this.props.listing.ModificationTimestamp : null}. 
                          <br/>
                          Disclaimer: All information deemed reliable but not guaranteed and should be independently verified. All 
                          properties are subject to prior sale, change or withdrawal. Neither the listing broker(s) nor Howard Hanna Real 
                          Estate Services shall be responsible for any typographical errors, misinformation, misprints, and shall be held 
                          totally harmless. © 2024 CNYIS, UNYREIS, WNYREIS. All rights reserved.
                        </Grid>
                        <Grid
                          item
                          sx={{
                            background: "rgba(255,255,255,0)",
                            overflowX:"hidden",
                            textAlign:"right",
                            fontSize: {
                              xs:"1rem",
                              sm:"1rem",
                              md:"1rem",
                              lg:"1rem",
                              xl:"1rem"
                            },
                            height:"100%",
                            width:"100%",   
                            border:"0px solid black",
                            fontWeight:"normal",
                          }}
                          xs={0}
                          sm={0}
                          md={1}
                        >
                        </Grid>
                        </Grid>
            }
            btmTxtColor={"black"}
            btmTxtSize={["1rem", ".75rem", ".75rem"]}
            btmTxtType={"normal"}
            btmTxtAlign={"justify"}
            btmHorizontalAlign={"flex-end"}
            btmVerticalAlign={"flex-start"}
            btmBgColor={"rgba(255,255,255,0)"}
            btmBorder={"0px solid black"}
            btmHeight={["425px", "100%", "100%"]}
            btmGraphType={null}
            btmGraphData={null}
            border={"0px solid black"}
            shadow={0}
            downloads={""}
            linksTo={""}
            growTime={4000}
            padding={"10px"}
            borderRadius={null}
            bgcolor={"rgba(255,255,255,0)"}
          />
        </Grid>
      </React.Fragment>
    );
  };

  getFooter = () => {
    return (
      <Grid
      item
      sx={{
        width: "100%",
        height: {
          xl: "10%",
          lg: "10%",
          md: "10%",
          sm: "10%",
        },
        padding: "5px",
        fontSize: "2rem",
      }}
      xs={12}
    >
      <Footer theme={this.props.theme}  />
    </Grid>
    )
  } 


  render() {

      // Call the searchNearbyRestaurants function with your current location
      this.getNearbyRestaurants(this.props.listing ? this.props.listing.Latitude : null, this.props.listing ? this.props.listing.Longitude : null);
      return (
        <Drawer
            anchor="bottom"
            open={this.props.showListingReport}
            onClose={this.props.toggleListingReport}
            PaperProps={{
              sx: {
                height: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                border: "0px solid gray",

                marginLeft: "auto",
              },
            }}
          >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            background: "#FFFFFF",
            height: {
              xs: "100%",
            },
            width: {
              xs: "100%",
            },
            
            border: "1px solid #333333",
          }}
        >

        <Grid
              item
              sx={{
                background: this.props.theme.bannerColor,
                cursor: "pointer",
                textAlign: "left",
                padding: "5px",
                borderBottom: "1px solid #A0A0A0",
                fontWeight: "bold",
                fontSize: {
                  xs: "0px",
                  sm: "0px",
                  md: "1rem",
                  lg: "1rem",
                  xl: "1.5rem",
                },
                height: {
                  xs: "12%",
                  sm: "12%",
                  md: "12%",
                  lg: "12%",
                  xl: "12%",
                },
              }}
              xs={4}
              md={4}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  height: "100%",
                  width: { md: "100%", xs: "100%" },
                  paddingLeft: "10px",               
                  color:this.props.theme.bannerTextColor,
                  fontSize:{
                    xs:"0rem",
                    sm:"0rem",
                    md:".8rem",
                    lg:".8rem",
                    xl:".8rem",
                  }
                }}
              >
             <Button
                onClick={this.props.toggleListingReport}
                variant={this.props.theme.buttonVariant}
                sx={{
                  '&:hover': {
                    border:"1px solid " + this.props.theme.bannerColor,
                    background:this.props.theme.bannerColor,
                    textDecoration: "underline"
                  },
      
                  marginRight: "0px",                  
                  textTransform: "none",
                  textAlign: "center",
                  fontSize:{
                    xs:".75rem",
                    sm:".75rem",
                    md:".75rem",
                    lg:"1rem",
                    xl:"1rem"
                  },
                  color:this.props.theme.buttonTextColor,
                  border:"1px solid " + this.props.theme.bannerColor,
                }}
              >
                  <Grid container>
                    <Grid item
                        sx={{fontSize:{
                        xs:".75rem",
                        sm:".75rem",
                        md:".75rem",
                        lg:"1rem",
                        xl:"1rem"
                      }
                    }}                  
                    >
                    <ArrowBackIosIcon/></Grid>
                    <Grid item
                        sx={{fontSize:{
                          xs:"1rem",
                          sm:"1rem",
                          md:".75rem",
                          lg:"1rem",
                          xl:"1rem"
                        }
                      }}                                        
                    >
                      {this.props.screenSize !== "xs" ? 'Back To Listings':'Back'}
                      
                    </Grid></Grid> 
                
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                background: this.props.theme.bannerColor,
                cursor: "pointer",
                textAlign: "center",
                padding: "10px",
                borderBottom: "1px solid #A0A0A0",
                fontWeight: "bold",
                height: {
                  xs: "12%",
                  sm: "12%",
                  md: "12%",
                  lg: "12%",
                  xl: "12%",
                },
              }}
              xs={8}
              md={8}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  height: "100%",
                  width: { md: "100%", xs: "100%" },
                  paddingBottom: "0px",
                }}
              >
                <Button
                  onClick={() => this.props.displayRequestShowing([this.props.listing])}
                  variant={this.props.theme.buttonVariant}
                  sx={{
                    '&:hover': {
                      border:"1px solid " + this.props.theme.buttonTextColor,
                      background:this.props.theme.buttonHoverColor
                    },
        
                    marginRight: "5px",
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderBottomLeftRadius: "20px",
                    textTransform: "none",
                    textAlign: "center",
                    color:this.props.theme.buttonTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    fontSize:{
                      xs:".75rem",
                      sm:".75rem",
                      md:".75rem",
                      lg:"1rem",
                      xl:"1rem",
    
                    }
                  }}
                >
                  Request A Showing
                </Button>
                <Button
                  onClick={this.props.toggleListingReport}
                  variant={this.props.theme.buttonVariant}
                  sx={{
                    '&:hover': {
                      border:"1px solid " + this.props.theme.buttonTextColor,
                      background:this.props.theme.buttonHoverColor
                    },
        
                    marginRight: "0px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                    borderBottomLeftRadius: "0px",
                    textTransform: "none",
                    textAlign: "center",
                    color:this.props.theme.buttonTextColor,
                    border:"1px solid " + this.props.theme.buttonColor,
                    fontSize:{
                      xs:".75rem",
                      sm:".75rem",
                      md:".75rem",
                      lg:"1rem",
                      xl:"1rem",
                    }
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>        
              <Grid
                item
                sx={{
                  
                  padding: "0px",
                  height: {
                    xl: "88%",
                    lg: "88%",
                    md: "88%",
                    sm: "100%",
                    xs: "100%",
                  },
                  overflowY: "scroll",
                  overflowX: "hidden",
                  fontSize: "16px",
                  border: "0px solid red",
                  background: "#FFFFFF",
                }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{
                    background: "#FFFFFF",
                    height: "100%",
                    
                    border: "0px solid #333333",
                  }}
                >
                  {this.getPrimaryTile()}
                  {this.props.listing && this.props.listing.ListAgentMlsId === "WILLSDO" && (this.props.listing.videos ? this.props.listing.videos.length:0) > 0 ? this.getVideoTile() : null}
                  {this.getNarativeTile()}
                  {this.getDetailsTiles()}                
                  {this.getMapTile()}                
                  {this.getWhatsNearbyTile()}                
                  {this.getPictureTiles()}
                  {this.getSlideShowTile()}
                  {this.getContactTile()}
                  {/*this.getMarketConditionsTile()*/}
                  {this.getFinancingTile()}
                  {this.getFinanceReportTile()}
                  {this.getHouseHuntingReportTile()}
                  {this.getListingReportTile()}
                  {this.getIdxTile()}
                  {this.getFooter()}

                </Grid>
          </Grid>
        </Grid>
        <SlideShowDialog 
          parentPage={this.props.parentPage}
          open={this.state.showSlideShowDialog}
          onClose={this.toggleSlideShowDialog}
          listing={this.props.listing ? this.props.listing: null}
          requestShowing={this.props.requestShowing}
          photos={this.state.photos}
          mapImage={this.mapImage}
          theme={this.props.theme}
          doNext={true}
          doPrevious={true}
          doCounter={true}
        />
        </Drawer>
      );
    }

}

export default ListingReport;
